import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Toasty(props) {
  // toast(props?.message);
  if (props.success === true) {
    toast.success(`${props.message}`);
  } else if (props.success === false) {
    toast.error(`${props.message}`);
  } else {
    toast.error(`${"Something is wrong"}`);
  }

  return (
    <div>
      {/* <button onClick={notify}>Notify !</button> */}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      >
        {props?.message}
      </ToastContainer>
    </div>
  );
}

export default Toasty;
