import React from "react";
import "./Footer.scss";

import { Link } from "react-router-dom";

export default function Footer(props) {
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="footer-top-block">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="footer_inner">
                                        <div className="footer_logo">
                                            <img
                                                src="images/footer_logo.svg"
                                                className="img-fluid"
                                                alt="Faircent"
                                            />
                                        </div>
                                        <p>
                                            Faircent is India&apos;s first peer-to-peer (P2P) lending
                                            platform to receive a Certificate of Registration (CoR) as
                                            an NBFC-P2P from the Reserve Bank of India (RBI).
                                        </p>
                                    </div>
                                </div>

                                <div className="col-md-8 offset-md-1">

                                    <div className="row">
                                        <div className="col-md-3 ">
                                            <div className="footer_inner">
                                                <h6>Contact Us</h6>

                                                <a href="https://www.faircent.com/about-us" target="_blank">About Us</a>
                                                <a href="https://www.faircent.com/careers" target="_blank">Careers</a>
                                                <a href="https://www.faircent.com/contact" target="_blank">Contact</a>
                                                <a href="https://www.faircent.com/affiliates-partners" target="_blank">Affiliates & Partners</a>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="footer_inner">
                                                <h6>Help</h6>
                                                <a href="https://www.faircent.com/faircent-faq" target="_blank">FAQ for Borrowers</a>
                                                <a href="https://www.faircent.com/faircent-faq#lender_faq" target="_blank">FAQ for Lenders</a>
                                                <a href="https://www.faircent.com/p2p-glossary" target="_blank">Glossary</a>
                                            </div>
                                        </div>
                                        <div className="col-md-5">
                                            <div className="footer_inner">
                                                <h6>Policies</h6>
                                                <a href="https://www.faircent.com/terms-conditions" target="_blank">Terms of Use</a>
                                                <a href="https://www.faircent.com/privacy-policy" target="_blank">Privacy & Security Policy</a>
                                                <a href="https://www.faircent.com/fair-practices-code.html" target="_blank">Fair Practices Code</a>
                                                <a href="https://www.faircent.com/grievance-redressal-policy.html" target="_blank">Grievance Redressal Policy</a>
                                                <a href="https://www.faircent.com/sites/default/files/lender_borrower.pdf" target="_blank">Lender Borrower Agreement</a>
                                            </div>

                                            <div className="social_links">
                                                <a href="https://www.facebook.com/faircent" target="_blank">
                                                    <img src="/images/facebook.svg" className="img-fluid" />
                                                </a>
                                                <a href="https://www.instagram.com/faircent.in/" target="_blank">
                                                    <img src="/images/instagram.svg" className="img-fluid" />
                                                </a>
                                                <a href="https://twitter.com/Faircent1" target="_blank">
                                                    <img src="/images/twitter.svg" className="img-fluid" />
                                                </a>
                                                <a href="https://www.youtube.com/faircentlive" target="_blank">
                                                    <img src="/images/youtube.svg" className="img-fluid" />
                                                </a>
                                                <a href="https://www.linkedin.com/company/faircent" target="_blank">
                                                    <img src="/images/linkedin.svg" className="img-fluid" />
                                                </a>
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="footer_disclaimer">
                            <h5>Disclaimer</h5>
                            <p>
                                Reserve Bank of India does not accept any responsibility for the
                                correctness of any of the statements or representations made or
                                opinions expressed by Fairassets Technologies India Pvt. Ltd,
                                and does not provide any assurance for repayment of the loans
                                lent on it. Fairassets Technologies India Pvt Ltd (Faircent.com)
                                is having a valid certificate of registration dated May 16th,
                                2018 issued by the Reserve Bank of India under Section 45 IA of
                                the Reserve Bank of India Act, 1934. However, the RBI does not
                                accept any responsibility or guarantee about the present
                                position as to the financial soundness of the company or for the
                                correctness of any of the statements or representations made or
                                the opinions expressed by the company and for repayment of
                                deposits / discharge of liabilities by the company.
                            </p>
                            <p>
                                The information contained herein is only to enable the Lender to
                                make a considered decision. Any decision taken by the Lender on
                                the basis of this information is the sole responsibility of the
                                Lender and Faircent is not liable. This information does not
                                include any sensitive personal data or information of the
                                Borrower. Faircent only facilitates a virtual meeting place
                                between the Borrowers and the Lenders on its online platform.
                                The decision to lend is entirely at the discretion of the Lender
                                and Faircent does not guarantee that the Borrowers will receive
                                any loans from the Lenders. Faircent merely aids and assist the
                                Lenders and the Borrowers listed on its website to make and
                                receive loans and charges a service fee from the Lenders and the
                                Borrowers for such assistance. Faircent is only an
                                ‘Intermediary’ under the provisions of the Information
                                Technology Act, 1999.
                            </p>
                            <p>
                                Our attention has recently been drawn to the fraudulent
                                activities of persons who misrepresent to third parties that
                                they are employees or authorized representatives of Faircent.com
                                in order to cheat them. Please be aware that the only legitimate
                                domain for Faircent.com is www.faircent.com. We urge you to
                                please verify that any borrowing/lending opportunities are
                                legitimate by checking with us at the below mentioned contact
                                details. If you are in doubt about any email, phone call,
                                communication, etc, please do not respond by revealing personal
                                information about yourself or your company and strictly refrain
                                from forwarding any money to third parties prior to verification
                                with Faircent.com . To verify borrowing/lending opportunities or
                                to report any suspicious behavior, please contact us at
                                support@faircent.com or 0120-4659902 between 9:30 am to 6:00 pm
                                IST from Monday to Saturday.
                            </p>
                            <p>
                                We are happy to observe that there are people who have
                                complimented Faircent by posting videos on YouTube and commented
                                on social media platforms. We wish to notify everyone that the
                                views expressed by these people on YouTube videos and other
                                social media platforms are their own and they have expressed
                                these views of their own free will without any request by
                                Faircent, or at the behest of Faircent in any manner. Faircent
                                therefore request you not to rely solely on their views and
                                comments, but to use your own judgement and knowledge about
                                investing on P2P platforms, including Faircent.
                            </p>
                        </div>

                        <div className="footer_copyright">
                            Copyright © Faircent 2023. All Rights Reserved.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
