import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home";
// import Header from "../components/Header/header";
import Headers from "../components/Header/Headers";
import Offers from "../pages/Offers";
import OfferRejected from "../pages/Offers/components/OfferRejected";
export default function AppRoute(props) {
  return (
    <>
      <Headers />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/offers" element={<Offers />} />
        {/* <Route path="/offer-approved" element={<Offers />} />
        <Route path="/offer-rejected" element={<OfferRejected />} /> */}
        {/* <Route path="/Offers/OfferApproved" element={<Offers />} /> */}
        <Route path="*" element={<p>Page Not Found!</p>} />
      </Routes>
    </>
  );
}
