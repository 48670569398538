import React from "react";
import "@fontsource/poppins/100.css";
import "@fontsource/poppins/200.css";
import "@fontsource/poppins/300.css";
import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";
import "@fontsource/poppins/800.css";
import "@fontsource/poppins/900.css";

import { createTheme, ThemeProvider } from "@mui/material";

export default function Theme(props) {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1377b8",
      },
      secondary: {
        main: "#b52126",
      },
    },
    typography: {
      fontFamily: ["Poppins", "sans-serif"].join(","),
      button: {
        fontWeight: 600,
        textTransform: "none",
      },
    },
  });

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
