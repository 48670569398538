import { useEffect, useState } from "react";
import axios from "axios";
import publicIp from "react-public-ip";

function useRequestApi() {
  const submitRequest = async (req) => {
    try {
      const res = await axios({
        url: req.url,
        method: req.method,
        data: req.data,
        headers: {
          "x-application-id": process.env.REACT_APP_APP_ID,
          "x-application-name": process.env.REACT_APP_NAME,
          ip_address: await publicIp.v4(),
          "Content-Type": "application/json",
        },
      });

      return res.data;
    } catch (error) {
      console.log("API Error", error);
    }
  };

  return { submitRequest };
}

export default useRequestApi;
