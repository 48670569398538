import CryptoJS from "crypto-js";

const compare_Age = (dob) => {
  /* const currentDate = new Date();
       const userDOB = new Date(dob);
       // Calculate the age difference in milliseconds
       const ageDifferenceInMs = currentDate - userDOB;
       // Calculate the age in years
       const ageInYears = Math.floor(ageDifferenceInMs / (1000 * 60 * 60 * 24 * 365.25));
       return ageInYears
       */

  if (!dob) return;

  const currentDate = new Date();
  if (new Date(dob) > currentDate) {
    //   setBirthDate("");
    //   setYears(null);
    //   setMonths(null);
    //   setDays(null);
    alert("Invalid Date of Birth");
    return;
  }

  const diffTime = currentDate - new Date(dob);
  const totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const totalYears = Math.floor(totalDays / 365.25);
  const totalMonths = Math.floor((totalDays % 365.25) / 30.4375);
  const count_Days = Math.floor((totalDays % 365.25) % 30.4375);

  // const results = `${totalYears} Years ${totalMonths} Months ${count_Days} Days `;
  const results = {
    totalYears: totalYears,
    months: totalMonths,
    count_days: count_Days,
  };
  return results;
};

const change_dateFormate = (value) => {
  let ddd = new Date(value);
  let dd = (ddd.getDate() < 10 ? "0" : "") + ddd.getDate();
  let mm = (ddd.getMonth() + 1 < 10 ? "0" : "") + (ddd.getMonth() + 1);
  let yyyy = ddd.getFullYear();

  var ddd1 = dd + "-" + mm + "-" + yyyy;
  return ddd1;
};

const calculate_age = (dob1) => {
  var today = new Date();
  var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }

  return age_now;
};

// ................encrypted data.....................
const encryptData = async (data) => {
  const secretPass = `${process.env.REACT_APP_SECRECT}`;

  const data1 = CryptoJS.AES.encrypt(JSON.stringify(data), secretPass).toString();

  return { usbData: data1 };
};

const encryptDataHeader = async (data) => {
  const secretPass = `${process.env.REACT_APP_SECRECT}`;
  const data1 = CryptoJS.AES.encrypt(JSON.stringify(data), secretPass).toString();

  return { "usb-data": data1 };
};

export { change_dateFormate, calculate_age, compare_Age, encryptData, encryptDataHeader };
