import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function OfferApproved(props) {
    const [userResult, setUserResult] = useState(null);
    const [userName, setuserName] = useState(null);
    const [userPercentAmt, setUserPercentAmt] = useState();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const user = localStorage?.getItem("registerdata");
        const name = localStorage?.getItem("userName");


        if (user) {

            setUserResult(JSON.parse(user));

            let pers_amt = JSON.parse(user);

            let ab = pers_amt.result?.Offer?.offer_amount / 150000 * 100;

            if (ab > 0) {
                setUserPercentAmt(ab);
            } else {
                setUserPercentAmt(0);

            }

        }
        if (name) {
            setuserName(name);
        }

        if (!location.state) {
            navigate(-1);
        }
    }, []);

    if (!userResult) {
        return null;
    }





    return (
        <div className="offers__page">
            <div className="container-xxl">
                <div className="row">
                    <div className="col-lg-5">
                        <div className="eezee_logo">
                            <img src="images/eezeeMain_logo.svg" className="" alt="" />
                        </div>
                    </div>
                </div>

                <section className="d-flex justify-content-center">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="congrate_logo">
                                <img src="images/congratulation.svg" className="" alt="" />
                            </div>
                            <p className="congrat_P">Congratulations! </p>
                            <p className="congrat_span">
                                Your Application has been Approved!
                            </p>
                        </div>
                    </div>
                </section>

                <div className="main_contain">
                    <div className="content_header"></div>
                    <div className="contents">
                        <p className="borrower_heading">
                            {" "}
                            Dear <strong>{userName} </strong>,
                        </p>
                        <p className="borrower_heading">
                            <span>Congratulations!</span> Your application for the{" "}
                            <strong>Faircent EEZEE</strong> has been approved!
                        </p>
                        <p className="borrower_heading">
                            {" "}
                            <strong>Here's your tentative loan offer:</strong>
                        </p>

                        <div className="mb-3 mt-3 ">
                            <div className=" borrower_amt mb-3">
                                <div className="row">
                                    <div className="col-md-9">Approved Loan Amount ₹</div>
                                    <div className="col-md-3 align-self-center">
                                        <div className="approved_amt">
                                            ₹{userResult.result?.Offer?.offer_amount.toLocaleString() || " 0"}
                                        </div>
                                    </div>
                                </div>


                                <div className="progress progrss_height">

                                    <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: `${userPercentAmt}% ` }}
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    ></div>
                                </div>



                            </div>
                            <p className="borrower_amt">
                                {" "}

                                Interest Rate: {userResult.result?.Offer?.offer_roi} % p.a.{" "}
                            </p>
                            <p className="borrower_amt">
                                {" "}
                                Loan Tenure:{" "}
                                {userResult.result?.Offer?.offer_tenure?.tenure_max} months{" "}
                            </p>
                            <p className="borrower_amt">
                                {" "}
                                Processing fee: ₹
                                {userResult.result?.Offer?.offer_pf.toLocaleString()}{" "}
                            </p>
                        </div>
                        <br />

                        <p className="borrower_heading">
                            {" "}
                            Our representative will be reaching out to you shortly to discuss
                            your loan offer in detail. Stay tuned! Your offer will be
                            finalized after your discussion with our representative.
                        </p>

                        <p className="borrower_heading">
                            Log in to your account to track the progress of your loan
                            application. We'll keep you updated every step of the way.
                        </p>

                        <p className="borrower_heading">
                            Thank you for choosing Faircent. Your financial journey begins
                            now.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OfferApproved;
