import React from "react";
import RegisterForm from "./RegisterForm";

export default function HeroBanner(props) {
  return (
    <div className="banner">
      <div className="container-xxl">
        <div className="row">
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-5">
                <div className="banner_logo">
                  <img src="images/logo.png" className="img-fluid" alt="" />
                </div>
                <div className="heading">
                  <h1>
                    Get a loan, from the <span>people</span>
                  </h1>
                  <p className="compet_rates_p">
                    fast, <b>eezee</b> and at most competitive rates!
                  </p>
                </div>
              </div>
              <div className="col-lg-7 pt-7">
                <div className="banner_image">
                  <img
                    src="images/borrower.png"
                    className="img-fluid image"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="borrower__form">
              <h2>Apply for a loan</h2>

              <RegisterForm />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
