import React from "react";

export default function Faq(props) {

  const faqTitle = "Frequently Asked Questions by Borrowers";
  const faqItems = [
    {
      question: "Why borrow through Faircent?",
      answer:
        "Faircent offers the borrowers a lower interest rate and less hassle than traditional financing options. It offers attractive fixed rates, an easy online application, friendly service and no hidden fees.",
    },
    {
      question: "How do I get started?",
      answer:
        "Simply click on the Sign Up Now icon and register through the simple step by step process as instructed. Once you have filled the basic information required and uploaded the documents required, Faircent risk assessment team will identity-verify, credit-check and risk-assess your profile. If qualified, your registration as a Borrower will be approved and you can apply for loan on the Faircent platform.",
    },
    {
      question: "Why do you require documents?",
      answer:
        "The documents are required to identity-verify, credit-check and risk-assess a borrower before registration. Apart from the CIBIL score, we use various other parameters like ability, stability, past performance and intention of the borrowers, which are evaluated basis these documents. The documents provided like salary slips, bank statements, ITR’s, balance sheets etc. help in taking a collective and transparent decision ensuring a fair field for the borrower.",
    },
    {
      question: "Why should I pay filing fee?",
      answer:
        "The filing fee is towards the considerable time, effort and resources spent by our credit evaluation mechanism to carefully assesses each borrower profile. The filing fee is non-refundable and payable at the time of disbursement only by the borrower funded through the Faircent portal.",
    },
    {
      question: "How much can I borrow?",
      answer:
        "At Faircent, loan disbursal is dependent on loan requirement, but loan amount can vary from Rs. 50,000/- to Rs. 1,50,000/-. ",
    },
    {
      question:
        "Will I be funded by an individual lender or by multiple lenders?",
      answer:
        "As per Faircent policy, no single lender can fund the entire loan requirement of a borrower. Hence, your loan will be funded by multiple lenders.",
    },
    {
      question:
        "Can I pre-pay my EMI? Can I repay my loan early if I like? Are there any charges?",
      answer:
        "Pre-closure of loan can be done after three months from the disbursal of the loan without any additional charges. Prior to three months, to protect lender interest, pre-closure can be done after paying balance interest due to lenders for a period of three months from the date of disbursal. This is payable directly to Lenders. Faircent charges a one-time non-refundable pre-payment fees of Rs. 500/-.",
    },
    {
      question: "What interest rates can I expect to pay?",
      answer:
        "Interest rates are suggested to each borrower by an automated credit appraisal system that uses the personal and financial information provided by borrowers across more than 120 criteria using more than 400 data points to risk-asses the borrowers and understand the strength of their credit profile. The interest rate thus assigned ranges from 12% to 28%. However, Unrated Borrowers can be registered at higher interest rate.",
    },
    {
      question:
        "On what parameters does Faircent verify my profile and check my credit-worthiness?",
      answer:
        "We use a highly-developed, technology-driven process of verification across more than 120 criteria using more than 400 data points basis the personal and financial information and documents provided by the borrower. Each borrower is identity-checked, credit-checked and risk-assessed by our experienced team. The intention, stability and ability of borrowers is evaluated and understood.",
    },
    {
      question: "Does Faircent.com undertake physical verification?",
      answer:
        "Yes, Physical verification at both residential and official address is undertaken after prior appointment. This is undertaken to verify the identity of the Borrower.",
    },
    {
      question: "Does Faircent require references?",
      answer:
        "Yes, as part of the verification process, we require 4 references from your professional or personal contacts who should be able to verify your identity, intention, ability and stability to take and repay a loan.",
    },
    {
      question: "How much time does it generally take before a loan is listed?",
      answer:
        " Once a borrower Signs Up, provides basic information, pays the non-refundable filing fee and uploads the required documents, the risk assessment team takes 48-72 (office) hours to evaluate and verify the borrower; basis which the credit appraisal mechanism sets the parameters for the loan –loan amount, rate of interest and loan tenure. Once the borrower accepts the same, the profile is made live on the portal. Thus, your loan requirement will be available on the website for lenders to fund between 48-72 hours from your first log-in on the website.",
    },
    {
      question:
        "How much time does it take to find a lender? How long can my loan request be available for lenders to fund?",
      answer:
        "All loans are listed on the platform for 15 days which may, under special circumstances, be extended to a maximum of 30 days. This is known as listing period. However, the funding of the loan cannot be predicted or controlled as it is dependent upon attracting sufficient lenders. Credit-worthy borrowers can get funded within minutes and some borrowers assigned high rate of interest can take a few days. You can monitor lenders interest anytime by logging into your account.",
    },
    {
      question: "How long is the disbursal process?",
      answer:
        "The disbursal process starts only after the borrower has paid the processing fees, signed the loan agreement and provided the PDCs, NACH Mandate and any other documents required. This process is driven by borrower interest. By using technology-driven facilities available only on Faircent you can reduce this time to less than 24 hours. Post this process funds are transferred from lender’s escrow account to the borrower’s bank account within 12-24 hours. Thus, the entire disbursal process takes 24-48 hours. It is transparent and driven by borrower and lender interest with Faircent acting only as a facilitator.",
    },
    {
      question: "Why was my application declined?",
      answer: `{Faircent holds the right to decline a listing in certain cases such as:

        The Borrower is unable to provide complete documentation or the same cannot be authenticated.These documents are required to evaluate the borrower’s credit-worthiness and intention/stability and ability to take and repay loan. Any deficiency on these parameters or in terms of past loan-performance of the borrower will lead to cancellation of listing of the borrower.Physical verification report is negative. It is established that the Borrower does not reside or work at the address submitted by the borrower.If at any stage of loan application, Faircent finds that misleading information was deliberately provided by the borrower with intentions to cheat and hurt the Faircent system, its Lenders, founders, stake holders, partners or employees or to infringe or steal the intellectual property of Faircent.}`,
    },
    {
      question: "What happens in case of delayed or non-payment of EMI?",
      answer: `{Borrowers are morally and legally obliged to pay all dues in a timely manner. In case the EMI is not paid on the scheduled time, a penal interest is payable as per Faircent policy for each instance of delayed repayment. These charges are paid to lenders. Faircent will also charge a non-refundable overdue charges per delayed payment which will be adjusted against repayment before adjustment of the delayed EMI amount. So, Faircent encourages all borrowers to make timely payments to avoid penalty charges.

        In the worst-case scenario, if Faircent has to issue a legal notice to a defaulting borrower on behalf of the lenders then a non-refundable charge of Rs. 500/- per legal notice will be payable by the borrower to Faircent.}`,
    },
    {
      question:
        " I have defaulted on loan payments before or Banks/Financial Institutions have rejected my loan application. Can I borrow on Faircent?",
      answer:
        "Faircent will not approve of a listing if it does not meet with our credit and verification standards. However, we encourage applicants to work on improving their credit history by repaying their debt in a timely manner and then try again after six months.",
    },
    {
      question: "How are repayments calculated?",
      answer:
        "Repayments are equated monthly installments calculated using the reducing balance method. Your installment per month will remain the same throughout the tenure of your loan. The first installment may be different depending on the date of disbursal.",
    },
    {
      question: "Will Faircent provide me with my credit score?",
      answer: "Faircent does not provide you with your credit score.",
    },
    {
      question: "What information can be viewed about me?",
      answer:
        "Once your listing has been approved by Faircent, your profile can be viewed by all registered Lenders. This includes your full name, profession, purpose of loan, email ID and financial information as this information helps you to get a loan faster. However, this information will not be visible to general visitors to the website. For more information, please view our Privacy Policy.",
    },
    {
      question:
        "Will my address and phone number be available online on the website?",
      answer:
        "We do not show anyone’s address or phone number to prevent harassment. For more information, please view our Privacy Policy.",
    },
    {
      question: "Can I change the loan amount I want to borrow after applying?",
      answer:
        "No, to avoid confusions and maintain lender interest, we do not make changes to a listing after it has been made live on the website. To increase the loan amount, you can create a fresh loan request for the difference.",
    },
    {
      question: "Can my loan request be terminated or reviewed at any stage?",
      answer:
        "Yes, if at any stage the information furnished by you or the verification(s) initiated by us are found to be incomplete, fraudulent or unverifiable then funding request is terminated and consequently, the invest button on the profile would be de-activated. No fee paid will be refunded.",
    },
    {
      question: "How do I cancel my loan application?",
      answer:
        "Please write to support@faircent.com requesting cancellation of your registration as Borrower. Loan cancellation can only happen prior to disbursal of funds.",
    },
    {
      question: "How do I change my bank details or set up a new direct debit?",
      answer:
        "Please write to support@faircent.com stating both your old and new bank details. Swapping charges of Rs. 500/- per swap will be applicable and payable to Faircent.",
    },
    {
      question: "Can I be both, a Lender and a Borrower?",
      answer: "No. You can either be a Lender or a Borrower at a given time.",
    },
    {
      question: "What is e-mandate? How can I verify my e-mandate?",
      answer:
        "In simple words, e-mandate facility allows borrowers to pay their monthly EMIs and repayments online via Electronic Money Transfer and Auto-Debit facilities. Faircent will email you the e-mandate link. Click on the link and verify using either your debit card or net banking. The whole process takes not more than a few minutes. Once the mandate is aproved, all future EMIs will be directly debited from your bank account and collected by Faicent in its collection account and then disbursed directly to each Lenders escrow acccount.",
    },
    {
      question: "How is e-mandate beneficial to me?",
      answer: `{ This facility has been introduced as it provides the following benefits to borrowers:

        Quick and secureConvenient and less time consuming: Whole process takes just a few minutesContactlessStress free. You do not have to worry about missing your EMI date.
        }`,
    },
  ];
  return (
    <div className="faq">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="title">
              <h2>You&apos;ve got questions? We&apos;ve got the answers.</h2>
              <p>{faqTitle}</p>
            </div>
            <div className="accordion" id="accordionList">
              {faqItems.map((faq, index) => (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`headingTwo${index}`}>
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapseItem${index}`}
                      aria-expanded="false"
                      aria-controls={`#collapseItem${index}`}
                    >
                      {faq.question}
                    </button>
                  </h2>
                  <div
                    id={`collapseItem${index}`}
                    className={`accordion-collapse collapse ${index === 0 && "show"
                      }`}
                    aria-labelledby={`headingTwo${index}`}
                    data-bs-parent="#accordionList"
                  >
                    <div className="accordion-body">{faq.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
