import React from "react";
import { BsCheck2 } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { Button } from "@mui/material";

export default function TakeLoan(props) {
  return (
    <div className="take__loans">
      <div className="container">
        <section className="mb-5">
          <h2>Why should i take the Faircent EEZEE loan?</h2>

          <div className="row mb-5">
            <div className="col-md-6 mb-5">
              <div className="content">
                <div className="icon">
                  <BsCheck2 />
                </div>
                <div className="text">
                  <p className="label_p">Flexible Tenure Options :</p>
                  <span>
                    Our groundbreaking Faircent Eezee loan offers a range of
                    flexible tenure periods, spanning from{" "}
                    <strong>3 to 18 months.</strong> This empowers you to tailor
                    your repayment tenure according to your unique financial
                    circumstances.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="content">
                <div className="icon">
                  <BsCheck2 />
                </div>
                <div className="text">
                  <p className="label_p">Tailored Loan Amounts:</p>
                  <span>
                    Access the funds you need with Faircent Eezee with loan
                    amounts ranging <strong>from ₹50,000 to ₹1.5 lakhs,</strong>{" "}
                    ensuring a versatile solution for various financial
                    requirements.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="content">
                <div className="icon">
                  <BsCheck2 />
                </div>
                <div className="text">
                  <p className="label_p">Smart Loan Approval :</p>
                  <span>
                    Leave traditional loan assessments behind. Faircent Eezee
                    leverages advanced <strong> bureau score analysis to approve loans.</strong>
                    This guarantees fair evaluation and widens access to credit
                    for all.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="content">
                <div className="icon">
                  <BsCheck2 />
                </div>
                <div className="text">
                  <p className="label_p">Effortless Application Processing: </p>
                  <span>
                    Experience a hassle-free application journey with <strong> zero
                      financial documentation.</strong> Faircent Eezee is meticulously
                    designed for swift and efficient loan application handling,
                    saving you valuable time.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="content">
                <div className="icon">
                  <BsCheck2 />
                </div>
                <div className="text">
                  <p className="label_p">Streamlined Approval Process : </p>
                  <span>
                    Bid farewell to cumbersome underwriting procedures. Faircent
                    Eezee simplifies the approval process with <strong> automated
                      underwriting,</strong> making it faster, smoother, and paves the way
                    for quicker loan disbursals.
                  </span>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <div className="content">
                <div className="icon">
                  <BsCheck2 />
                </div>
                <div className="text">
                  <p className="label_p">Prompt Funds Disbursement: </p>
                  <span>
                    We understand the importance of urgency. With the Faircent
                    EEZEE, approved funds reach your hands promptly <strong>within 24 to
                      48 hours,</strong> addressing your financial needs without delay.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="row">
          <div className="col-md-12">
            <div className="heading2">
              <h3>What kind of a loan can I get?</h3>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="content2">
              <div className="icon">
                <BsCheck2 />
              </div>
              <div className="text">
                <h4 className="typeOfLoan_h4_head2">Loan Amount:</h4>
                <h4 className="typeOfLoan_h4_head1"> from ₹50k to ₹1.5L</h4>
                <span>as per your eligibility </span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="content2">
              <div className="icon">
                <BsCheck2 />
              </div>

              <div className="text">
                <h4 className="typeOfLoan_h4_head2">Loan Tenure:</h4>
                <h4 className="typeOfLoan_h4_head1">from 3 to 18 months</h4>
                <span>for the short and long term </span>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-4">
            <div className="content2">
              <div className="icon">
                <BsCheck2 />
              </div>
              <div className="text">
                <h4 className="typeOfLoan_h4_head2">Documentation:</h4>
                <h4 className="typeOfLoan_h4_head1"> 100% online</h4>
                <span>&nbsp;No physical docs required </span>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div className="row">
          <div className="col-md-12 col-lg-4">
            <div className="content2">
              <div className="icon">
                <BsCheck2 />
              </div>
              <div className="text">
                <h4 className="typeOfLoan_h4_head2">Interest Rates:</h4>
                <h4 className="typeOfLoan_h4_head1"> from 10% pa onwards</h4>
                <span>the best of rates provided by the people </span>
              </div>
            </div>
          </div>

          <div className="col-md-12 col-lg-4">
            <div className="content2">
              <div className="icon">
                <BsCheck2 />
              </div>
              <div className="text">
                <h4 className="typeOfLoan_h4_head2">Security:</h4>
                <h4 className="typeOfLoan_h4_head1"> Zero Collateral</h4>
                <span>with no security required </span>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4">
            <div className="content2">
              <div className="icon">
                <BsCheck2 />
              </div>
              <div className="text">
                <h4 className="typeOfLoan_h4_head2">Fees:</h4>
                <h4 className="typeOfLoan_h4_head1"> Zero Registration Fee</h4>
                <span>with low processing fee and no hidden charges. </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
