import React, { useState } from "react";

import { Formik } from "formik";
import * as Yup from "yup";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// import styles from "./Getloan.module.scss";
import Button from "@mui/material/Button";
import { BsArrowRightShort } from "react-icons/bs";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateRangeIcon, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { validationSchema } from "./Validator";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import useRequestApi from "../../../hooks/useRequestApi";
import {
  checkPanVerify,
  checkMobileVerify,
  checkOTPVerify,
  userRegistration,
} from "../Service";
import { useNavigate } from "react-router-dom";
import Toasty from "../../../components/Toasty/Toasty";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSearchParams } from "react-router-dom";
import useRequestApiSecret from "../../../hooks/useRequestApiSecret";

const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z0-9]+$/;
// const ALPHA_NUMERIC_DASH_REGEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

export default function RegisterForm(props) {
  const { submitRequest } = useRequestApi();
  const { submitSecretRequest } = useRequestApiSecret();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const [pan_verified, setPan_verified] = useState();
  const [showPassword, setShowPassword] = React.useState(false);
  const [otpSendStatus, setOtpSendStatus] = React.useState(false);
  const [otpVerifiStatus, setOtpVerifitSatus] = React.useState();
  const [otpMobile, setOtpMobile] = React.useState("");
  const [oTPValue, setOTPValue] = React.useState("");
  const [isPanProgress, setIsPanProgress] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const check_PAN = async (values) => {
    setIsPanProgress(true);
    if (values.firstName === "") {
      alert("Please Enter firstname");

      return;
    } else {
      const req = await checkPanVerify(values);

      const res = await submitSecretRequest(req);

      await setIsPanProgress(false);
      try {
        await setPan_verified(res["success"]);
        Toasty(res);
      } catch (err) {
        Toasty(err);
        setIsPanProgress(false);
      }
    }
  };

  const check_Mob = async () => {
    if (otpMobile.length === 10) {
      const req = checkMobileVerify(otpMobile);
      const res = await submitRequest(req);

      // console.log("------------->>>>", res);

      Toasty(res);

      setOtpSendStatus(true);
    }
  };

  const check_OTP = async () => {
    if (oTPValue.length === 6) {
      const req = checkOTPVerify(otpMobile, oTPValue);

      const res = await submitRequest(req);
      Toasty(res);

      setOtpVerifitSatus(res.success);
    }
  };

  const formRegister = async (value) => {
    // const req = userRegistration(value, searchParams);
    if (pan_verified == true && otpVerifiStatus == true) {
      const req = userRegistration(value, searchParams);

      localStorage.setItem("userName", JSON.stringify(value.firstName));
      const res = await submitRequest(req);
      localStorage.setItem("registerdata", JSON.stringify(res));

      if (res?.success === true) {
        navigate("/offers", { replace: true, state: true });
        Toasty(res);
      } else {
        Toasty(res);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          pan_no: "",
          aadhar_no: "",
          password: "",
          mobile: "",
          otp: "",
          dob: "",
          pincode: "",
          monthlyIncome: "",
          empType: "",
          residenceType: "",
          workingSince: "",
          termsCheck: false,
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          setSubmitting(true);
          setTimeout(async () => {
            // console.log("data------------", values);

            setSubmitting(true);

            await formRegister(values);

            // resetForm();
            setSubmitting(false);
          }, 500);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="firstName"
                    label="First name"
                    variant="standard"
                    helperText={touched.firstName && errors.firstName}
                    error={touched.firstName && errors.firstName ? true : false}
                    value={values.firstName}
                    onChange={handleChange}
                    inputProps={{
                      readOnly:
                        pan_verified === undefined || pan_verified === false
                          ? false
                          : true,
                      style: { fontSize: 14, textTransform: "capitalize" },
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="lastName"
                    label="Last name"
                    variant="standard"
                    helperText={touched.lastName && errors.lastName}
                    error={touched.lastName && errors.lastName ? true : false}
                    value={values.lastName || ""}
                    onChange={handleChange}
                    inputProps={{
                      readOnly:
                        pan_verified === undefined || pan_verified === false
                          ? false
                          : true,
                      style: { fontSize: 14, textTransform: "capitalize" },
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    {...props}
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="pan_no"
                    label="PAN Number"
                    variant="standard"
                    helperText={touched.pan_no && errors.pan_no}
                    error={touched.pan_no && errors.pan_no ? true : false}
                    value={values.pan_no}
                    onChange={(e) => {
                      setFieldValue("pan_no", e.target.value);
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    onKeyDown={(event) => {
                      if (!ALPHA_NUMERIC_DASH_REGEX.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .toString()
                        .slice(0, 10)
                        .toUpperCase();
                      let panvalue = e.target.value;
                      if (panvalue.length === 10) {
                        return;
                      } else {
                        setPan_verified();
                        setIsPanProgress(false);
                      }
                    }}
                    InputProps={{
                      style: { fontSize: 14, textTransform: "uppercase" },
                      readOnly: false,

                      endAdornment:
                        values?.pan_no.length === 10 ? (
                          <InputAdornment position="end">
                            {
                              values?.pan_no.length === 10 &&
                              pan_verified === undefined &&
                              isPanProgress === false ? (
                                <div
                                  aria-label="toggle sendotp visibility"
                                  className="text_field_right"
                                  onClick={() => {
                                    check_PAN(values);
                                  }}
                                  disabled={isPanProgress}
                                >
                                  {pan_verified === undefined ||
                                  pan_verified === false ? (
                                    "Verify PAN"
                                  ) : (
                                    <CircularProgress
                                      size="2rem"
                                      sx={{ color: "#ffffff", ml: 1 }}
                                    />
                                  )}
                                </div>
                              ) : values?.pan_no.length === 10 &&
                                pan_verified === false &&
                                isPanProgress === false ? (
                                <div
                                  aria-label="toggle sendotp visibility"
                                  className="text_field_right"
                                  onClick={() => {
                                    check_PAN(values);
                                  }}
                                  disabled={isPanProgress}
                                >
                                  {pan_verified === undefined ||
                                  pan_verified === false ? (
                                    "Verify PAN"
                                  ) : (
                                    <CircularProgress
                                      size="2rem"
                                      sx={{ color: "#ffffff", ml: 1 }}
                                    />
                                  )}
                                </div>
                              ) : values?.pan_no.length === 10 &&
                                pan_verified === true &&
                                isPanProgress === false ? (
                                <div></div>
                              ) : (
                                <CircularProgress
                                  size="2rem"
                                  sx={{ color: "bbbbbb", ml: 1 }}
                                />
                              )

                              // null
                            }

                            {pan_verified === true ? (
                              <img
                                src="images/check_small.png"
                                className="textrightIcon"
                                alt=""
                              />
                            ) : pan_verified === false ? (
                              <img
                                src="images/exc_small.png"
                                className="textrightIcon"
                                alt=""
                              />
                            ) : (
                              ""
                            )}
                          </InputAdornment>
                        ) : null,
                    }}
                  />

                  <FormHelperText className="helper_text">
                    {values?.pan_no.length === 10 &&
                      pan_verified !== true &&
                      "Please verify PAN"}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="aadhar_no"
                    label="Aadhar Number"
                    variant="standard"
                    helperText={touched.aadhar_no && errors.aadhar_no}
                    error={touched.aadhar_no && errors.aadhar_no ? true : false}
                    value={values.aadhar_no}
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 14 },
                      maxLength: 12,
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type="number"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 12);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      format="DD-MM-YYYY"
                      slotProps={{
                        textField: { variant: "standard" },
                      }}
                      onChange={(e) => {
                        setFieldValue("dob", e["$d"]);
                      }}
                    />
                  </LocalizationProvider>
                  <FormHelperText className="helper_text">
                    {touched.dob && errors.dob}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  {/* <FormControl variant="standard" size="small" fullWidth>
                    <InputLabel id="select-pincode">PIN code</InputLabel>
                    <Select
                      labelId="select-pincode"
                      id="select-pincode"
                      value={values.pincode}
                      label="PIN code"
                      onChange={(e) => {
                        setFieldValue("pincode", e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={110096}>110096</MenuItem>
                      <MenuItem value={110097}>110097</MenuItem>
                      <MenuItem value={110098}>110098</MenuItem>
                    </Select>
                    <FormHelperText className="helper_text">
                      {touched.pincode && errors.pincode}
                    </FormHelperText>
                  </FormControl> */}
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="pincode"
                    label="Pin Code"
                    variant="standard"
                    helperText={touched.pincode && errors.pincode}
                    error={touched.pincode && errors.pincode ? true : false}
                    value={values.pincode}
                    onChange={handleChange}
                    inputProps={{
                      style: { fontSize: 14 },
                      maxLength: 6,
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type="number"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 6);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="mobile"
                    label="Mobile Number"
                    variant="standard"
                    helperText={touched.mobile && errors.mobile}
                    error={touched.mobile && errors.mobile ? true : false}
                    value={values.mobile}
                    onChange={(e) => {
                      setFieldValue("mobile", e.target.value);
                    }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type="number"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 10);

                      let mobno = e.target.value;
                      if (mobno.length === 10) {
                        setOtpMobile(mobno);
                        return;
                      } else {
                        // alert("Somethig is wrong");
                        setOtpVerifitSatus();
                        setFieldValue("otp", "");
                      }
                    }}
                    InputProps={{
                      style: { fontSize: 13 },

                      endAdornment:
                        values?.mobile.length === 10 &&
                        otpVerifiStatus === undefined ? (
                          <InputAdornment position="end">
                            <div
                              aria-label="toggle sendotp visibility"
                              // variant="text"
                              className="text_field_right"
                              onClick={check_Mob}
                            >
                              {!otpSendStatus ? "Send OTP" : "Resend"}
                            </div>
                          </InputAdornment>
                        ) : values?.mobile.length === 10 &&
                          otpVerifiStatus === false ? (
                          <InputAdornment position="end">
                            <div
                              aria-label="toggle sendotp visibility"
                              // variant="text"
                              className="text_field_right"
                              onClick={check_Mob}
                            >
                              {!otpSendStatus ? "Send OTP" : "Resend"}
                            </div>
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="otp"
                    label="OTP"
                    variant="standard"
                    helperText={touched.otp && errors.otp}
                    error={touched.otp && errors.otp ? true : false}
                    value={values.otp}
                    // onChange={handleChange}
                    onChange={(e) => {
                      setFieldValue("otp", e.target.value);
                    }}
                    inputProps={{ style: { fontSize: 14 }, maxLength: 6 }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type="number"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 6);

                      let otp_no = e.target.value;
                      if (otp_no.length === 6) {
                        // check_OTP(otp_no, values);
                        setOTPValue(otp_no);
                        setOtpVerifitSatus();
                        return;
                      } else {
                        // alert("Somethig is wrong");
                      }
                    }}
                    InputProps={{
                      style: { fontSize: 14 },

                      endAdornment:
                        values?.otp.length === 6 ? (
                          <InputAdornment position="end">
                            {oTPValue.length === 6 &&
                            otpVerifiStatus === undefined ? (
                              <div
                                aria-label="toggle sendotp visibility"
                                className="text_field_right"
                                onClick={check_OTP}
                              >
                                {"Verify OTP "}
                              </div>
                            ) : oTPValue.length === 6 &&
                              otpVerifiStatus === false ? (
                              <div
                                aria-label="toggle sendotp visibility"
                                className="text_field_right"
                                onClick={check_OTP}
                              >
                                {"Verify OTP "}
                              </div>
                            ) : null}

                            {otpVerifiStatus === true ? (
                              <img
                                src="images/check_small.png"
                                className=""
                                alt=""
                              />
                            ) : otpVerifiStatus === false ? (
                              <img
                                src="images/exc_small.png"
                                className=""
                                alt=""
                              />
                            ) : null}
                          </InputAdornment>
                        ) : null,
                    }}
                  />
                  <FormHelperText className="helper_text">
                    {oTPValue.length === 6 &&
                      otpVerifiStatus !== true &&
                      "Please verify OTP"}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="email"
                    label="Email ID"
                    variant="standard"
                    helperText={touched.email && errors.email}
                    error={touched.email && errors.email ? true : false}
                    value={values.email}
                    onChange={handleChange}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="password"
                    label="Password"
                    variant="standard"
                    helperText={touched.password && errors.password}
                    error={touched.password && errors.password ? true : false}
                    value={values.password}
                    onChange={handleChange}
                    // inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{ style: { fontSize: 14 } }}
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      style: { fontSize: 14 },

                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <TextField
                    fullWidth
                    autoComplete="off"
                    size="small"
                    name="monthlyIncome"
                    label="Monthly Income"
                    variant="standard"
                    helperText={touched.monthlyIncome && errors.monthlyIncome}
                    error={
                      touched.monthlyIncome && errors.monthlyIncome
                        ? true
                        : false
                    }
                    value={values.monthlyIncome}
                    onChange={handleChange}
                    inputProps={{ style: { fontSize: 14 } }}
                    InputLabelProps={{
                      style: {
                        fontSize: 14,
                        //  color: "#4E99CA"
                      },
                    }}
                    type="number"
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 7);
                    }}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <FormControl variant="standard" size="small" fullWidth>
                    <InputLabel id="select-emp-type">
                      Employment Type
                    </InputLabel>
                    <Select
                      labelId="select-emp-type"
                      id="select-emp-type"
                      label="Select Employment Types"
                      value={values.empType}
                      error={touched.empType && errors.empType ? true : false}
                      onChange={(e) => {
                        setFieldValue("empType", e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"SALARIED"}>Salaried</MenuItem>
                      <MenuItem value={"SELF_EMPLOYED"}>Self Employed</MenuItem>
                    </Select>
                    <FormHelperText className="helper_text">
                      {touched.empType && errors.empType}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <FormControl variant="standard" size="small" fullWidth>
                    <InputLabel id="select-emp-type">Residence Type</InputLabel>
                    <Select
                      labelId="select-emp-type"
                      id="select-emp-type"
                      label="Select Employment Types"
                      value={values.residenceType}
                      error={
                        touched.residenceType && errors.residenceType
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        setFieldValue("residenceType", e.target.value);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"Owned"}>Owned</MenuItem>
                      <MenuItem value={"Rented"}>Rented</MenuItem>
                      <MenuItem value={"Relative"}>Relative</MenuItem>
                      {/* <MenuItem value={"Residence cum office"}>Residence cum office</MenuItem> */}
                    </Select>
                    <FormHelperText className="helper_text">
                      {touched.residenceType && errors.residenceType}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="col-12 mb-4">
                <div className="form-group">
                  <FormControl variant="standard" size="small" fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        // value={values.dob}
                        label="Working since"
                        format="DD-MM-YYYY"
                        slotProps={{
                          textField: { variant: "standard" },
                          // label: { textAlign: "right" },
                        }}
                        onChange={(e) => {
                          setFieldValue("workingSince", e["$d"]);
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                  <FormHelperText className="helper_text">
                    {touched.workingSince && errors.workingSince}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-md-12 term__condition__wrapper">
                <div className="form-group">
                  <div className="term__condition">
                    <div>
                      <Checkbox
                        checked={values.termsCheck}
                        // value={values.termsCheck}
                        onChange={(e) => {
                          setFieldValue("termsCheck", true);
                        }}
                      />
                    </div>
                    <div>
                      <p className="p1">
                        I have read and agreed to the{" "}
                        <a
                          href="https://www.faircent.in/terms-conditions"
                          target="_blank"
                        >
                          Terms Of Use
                        </a>{" "}
                        and
                        <a
                          href="https://www.faircent.in/privacy-policy"
                          target="_blank"
                        >
                          {" "}
                          Privacy and Security Policy
                        </a>
                        . I authorize faircent.com to make any enquiries with
                        any finance company or bank or registered credit bureau
                        regarding my credit history with them. I consent to the
                        search, download, and upload of my CKYC information to
                        CERSAI.
                      </p>
                    </div>
                  </div>
                  <FormHelperText className="helper_text">
                    {touched.termsCheck && errors.termsCheck}
                  </FormHelperText>
                </div>
              </div>

              <div className="col-md-12 ">
                <div style={{}}>
                  <Button
                    fullWidth
                    endIcon={<BsArrowRightShort className="icons" />}
                    className="btn_payemi"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    APPLY NOW{" "}
                    {isSubmitting && (
                      <CircularProgress
                        size="2rem"
                        sx={{ color: "#ffffff", ml: 1 }}
                      />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <ToastContainer theme="colored" />
    </>
  );
}
