import React, { useEffect } from "react";
import Theme from "./theme/Theme";
import AppRoute from "./routes/AppRoute";
import { useSearchParams } from "react-router-dom";


function App(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  // const { paramsApp, setParamsApp } = useContext(AppContext);

  const params = Object.fromEntries([...searchParams]);

  localStorage.setItem("paramsItem", JSON.stringify(params));

  return (
    <Theme>
      <AppRoute />
    </Theme>
  );
}

export default App;
