import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function OfferRejected(props) {
  const [userName, setuserName] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const name = localStorage?.getItem("userName");

    if (name) {
      setuserName(name);
    }

    if (!location.state) {
      navigate(-1);
    }
  }, []);


  return (
    <div className="offers__rejected">
      <div className="container-xxl">
        <div className="row">
          <div className="col-lg-5">
            <div className="banner_logo">
              <img
                src="images/eezeeMain_logo.svg"
                className="img-fluid"
                alt=""
              />
            </div>
          </div>
        </div>

        <section className="d-flex justify-content-center mt-2">
          <div className="row">
            <div className="col-md-12">
              <div className="sad_logo">
                <img src="images/sad.svg" className="" alt="" />
              </div>
              <p className="reject_span">
                Important Update Regarding Your Application
              </p>
            </div>
          </div>
        </section>

        <div className="main_contain">
          <div className="content_header"></div>
          <div className="contents">
            <p className="borrower_heading">
              {" "}
              Dear <strong> {userName} </strong>,
            </p>

            <p className="borrower_heading">
              {" "}
              We appreciate your interest in the Faircent EEZEE  and taking the
              time to apply for a loan. After careful review of your
              application, we regret to inform you that your loan application
              has not been approved at this time. .
            </p>

            <p className="borrower_heading">
              We encourage you to consider reapplying for a loan after a period
              of 45 days. Our team is available to discuss any questions you may
              have or provide guidance on the application process.
            </p>

            <p className="borrower_heading">
              Thank you for considering Faircent.
            </p>
          </div>
        </div>


      </div>
    </div>
  );
}

export default OfferRejected;
