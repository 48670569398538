import React from "react";
import { Button } from "@mui/material";
import { BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

export default function Records(props) {
  return (
    <div className="borrower__record">
      <div className="container-fluid">


        <div className="row">
          <div className="col-12  col-md-12">


            <div className="record_wrapper">
              <div className="record_box">
                <div className=" records">
                  <h4>
                    ₹3,400 Crore + <span>disbursed in loans</span>
                  </h4>
                </div>

                <div className=" records">
                  <h4>
                    35 Lakh + <span>borrowers have joined us</span>
                  </h4>
                </div>

                <div className="records">
                  <h4>
                    71 Lakh + <span>Loans funded so far</span>
                  </h4>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
