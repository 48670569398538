import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string("").required("Please enter the first name."),
  lastName: Yup.string(""),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email address.")
    .required("Please enter the email address."),
  pan_no: Yup.string("")
    .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Enter the valid PAN No.")
    // .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "Enter the valid PAN No.")
    .required("Please enter the valid PAN No."),
  aadhar_no: Yup.string("")
    .min(12, "Aadhar  should be  min 12 digits")
    .max(12, "Aadhar  should be  max 12 digits")
    .required("Please enter valid Aadhar number .")
    .test(
      "Is positive?",
      "Please enter the valid 12 digit number ",
      (value) => value > 0
    ),
  password: Yup.string("").min(6, "Password  should be  min 6 digits")
    .required("Please enter the password."),
  mobile: Yup.string("")
    .min(10, "Mobile  should be  min 10 digits")
    .max(10, "Mobile  should be  max 10 digits")
    .required("Enter valid mobile number .")
    .test(
      "Is positive?",
      "Please enter the valid 10 digit number eg: 91..",
      (value) => value > 0
    ),

  otp: Yup.string("").required("Please enter the otp."),

  dob: Yup.string("").required("Please select Date of Birth."),
  pincode: Yup.string("")
    .min(6, "PIN Code  should be  min 6 digits")
    .max(6, "PIN Code should be  max 6 digits")
    .required("Enter valid PIN  Code.")
    .test(
      "Is positive?",
      "Please enter the valid 6 digit Pin Code",
      (value) => value > 0
    ),
  empType: Yup.string("").required("Please select Employment Type."),
  residenceType: Yup.string("").required("Please select Residence Type."),
  workingSince: Yup.string("").required("Please select working since"),
  monthlyIncome: Yup.string("").required("Please enter Monthly Income."),
  termsCheck: Yup.boolean()
    .required("Required")
    .oneOf([true], "You must accept the terms and conditions."),
});
