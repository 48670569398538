import React from "react";
import { Button, CircularProgress } from "@mui/material";
import { BsArrowRightShort } from "react-icons/bs";
import "./CustomButton.scss";

export default function CustomButton(props) {
    const { text, isSubmitting, onClick, color, textColor, fullWidth } = props;

    return (
        <div className="custom__button">
            <Button
                variant="contained"
                fullWidth={fullWidth ? true : false}
                type="submit"
                size="large"
                disabled={isSubmitting}
                onClick={onClick}
                sx={{
                    borderRadius: 28,
                    backgroundColor: color,
                    color: textColor ? textColor : "#ffffff",
                    fontWeight: "700",
                    "&.Mui-disabled": {
                        background: "#1377b8",
                        color: "#ffffff",
                    },
                }}
            >
                {text}
                {isSubmitting ? (
                    <CircularProgress
                        size="1.4rem"
                        style={{ color: "#ffffff", marginLeft: 6 }}
                    />
                ) : (
                    <BsArrowRightShort />
                )}
            </Button>
        </div>
    );
}
