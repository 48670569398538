import React from "react";
import { Box } from "@mui/material";

// components
import HeroBanner from "./components/HeroBanner";
import Records from "./components/Records";
import TakeLoan from "./components/TakeLoan";
import Faq from "./components/Faq";
import GetMoney from "./components/GetMoney";
import "./Home.scss";
import Footer from "../../components/Footer/Footer";

export default function Home(props) {
  return (
    <Box className="home__page">
      <HeroBanner />
      <Records />
      <TakeLoan />
      <Faq />
      <GetMoney />
      <Footer />
    </Box>
  );
}
