import { useEffect, useState } from "react";
import axios from "axios";
import publicIp from "react-public-ip";
import { encryptData, encryptDataHeader } from "../pages/Home/Script";

function useRequestApiSecret() {
  const submitSecretRequest = async (req) => {
    // console.log("..................", await encryptData(req));

    let headerEncrypt = encryptDataHeader(
      JSON.stringify({
        "x-application-id": process.env.REACT_APP_APP_ID,
        "x-application-name": process.env.REACT_APP_NAME,
        ip_address: await publicIp.v4(),
        "Content-Type": "application/json",
      })
    );

    try {
      const res = await axios({
        url: req.url,
        method: req.method,
        data: await encryptData(req.data),
        headers: await headerEncrypt,
      });

      return res.data;
    } catch (error) {
      console.log("API Error", error);
    }
  };

  return { submitSecretRequest };
}

export default useRequestApiSecret;
