import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
// components

import "./Offers.scss";
import Footer from "../../components/Footer/Footer";
import OfferApproved from "./components/OfferApproved";
import OfferRejected from "./components/OfferRejected";
import GetMoney from "../Home/components/GetMoney";
import { useLocation, useNavigate } from "react-router-dom";

export default function Offers(props) {
  const [userResult, setUserResult] = useState();

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    const user = localStorage?.getItem("registerdata");
    if (user) {
      setUserResult(JSON.parse(user));
    }
    if (!location.state) {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <Box className="offers__page">
        {userResult?.result?.lead_status == "Approved" &&
        userResult?.result?.Offer !== "" ? (
          <OfferApproved />
        ) : (
          <OfferRejected />
        )}
      </Box>
      <GetMoney />
      <Footer />
    </>
  );
}
