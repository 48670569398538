import React from "react";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { BsTelephoneFill } from "react-icons/bs";

export default function GetMoney(props) {
  return (
    <div className="growth__story">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div>
              <h2>Get the money you need to grow!</h2>
              <div className="invest__btn">
                <CustomButton
                  text="Get A Loan Now"
                  isSubmitting={false}
                  onClick={() => window.scrollTo(0, 0)}
                  color="#ffffff"
                  textColor="#b52126"
                />
              </div>
              <h3>
                <BsTelephoneFill />
                8010052020
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
