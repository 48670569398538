import axios from "axios";
import { change_dateFormate } from "./Script";
import CryptoJS from "crypto-js";
import moment from "moment";

export const checkPanVerify = async (value) => {
  return {
    // url: `${process.env.REACT_APP_BASE_URL}/borrower/verify/pan`,
    url: `${process.env.REACT_APP_BASE_URL}/borrower/pancard/validate`,
    method: "POST",
    data: {
      pan: value.pan_no,
      name: value.firstName + " " + value.lastName,
      reqTime: moment().unix(),
      siteUrl: window.location.href,
      // siteUrl: "faircent",
      // reqTime: Math.floor(new Date().getTime() / 1000)
    },
  };
};

export const checkMobileVerify = (otpMobile) => {
  return {
    url: `${process.env.REACT_APP_BASE_URL}/borrower/otp/to/mobile`,
    method: "POST",
    data: {
      mobile: otpMobile,
      type: "MOB_OTP_USER_REGISTRATION",
    },
  };
};

export const checkOTPVerify = (otpMobile, oTPValue) => {
  return {
    url: `${process.env.REACT_APP_BASE_URL}/borrower/verify/otp`,
    method: "POST",
    data: {
      mobile: otpMobile,
      otp: oTPValue,
    },
  };
};

export const userRegistration = (value, searchParams) => {
  return {
    url: `${process.env.REACT_APP_BASE_URL}/borrower/alliance/user/registration`,
    method: "POST",
    data: {
      fname: value.firstName,
      lname: value.lastName,
      aadhaar_number: value.aadhar_no.toString(),
      pan: value.pan_no,
      dob: change_dateFormate(value.dob),
      mobile: value.mobile.toString(),
      email: value.email,
      password: value.password,
      monthly_income: value.monthlyIncome,
      employment_type: value.empType,
      pincode: value.pincode,
      residence_type: value.residenceType,
      job_vintage: change_dateFormate(value.workingSince),
      ext_user_id: "234444",
      ckyc_id: "",
      otp: value.otp,
      utm_source: searchParams.get("utm_source") || "",
      campaign_id: searchParams.get("campaign_id") || "",
      campaign_name: searchParams.get("campaign_name") || "",
      gcl_id: searchParams.get("gcl_id") || "",
      agf: searchParams.get("agf") || "",
      utm_medium: searchParams.get("utm_medium") || "",
    },
  };
};
